<template>
  <div
    class="md:w-1/2 h-full flex flex-col bg-white shadow sm:rounded-lg mt-6 md:mt-0"
  >
    <div class="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
      <div class="flex items-start justify-between">
        <h2 class="text-lg font-medium text-indigo-600" id="slide-over-title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
            ></path>
          </svg>
        </h2>
        <div class="ml-3 h-7 flex items-center">
          <button
            @click="deleteAllCart"
            type="button"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="mt-8">
        <div class="flow-root">
          <ul role="list" class="-my-6 divide-y divide-gray-200">
            <li class="py-6 flex" v-for="(item, index) in cart" :key="index">
              <div class="flex-1 flex flex-col">
                <div class="flex justify-between">
                  <h3>
                    <span class="text-base font-medium text-gray-900"
                      >{{ item.name }} <br />{{ currency(item.price) }}</span
                    >
                    <br />
                    <p class="mt-1 text-sm text-gray-500">
                      Stock {{ item.stock }}
                    </p>
                  </h3>
                  <div>
                    <button
                      @click="decrementQty(item)"
                      type="button"
                      class="font-medium text-indigo-600 hover:text-indigo-500 mr-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg></button
                    ><input
                      v-on:keyup="updateQuantity(item)"
                      v-model.number="item.quantity"
                      type="text"
                      name="qty"
                      autocomplete="off"
                      class="relative p-1 h-10 w-16 rounded border placeholder-gray-500 text-gray-900 focus:outline-none focus:border-white focus:ring focus:z-10 sm:text-sm text-center"
                      spellcheck="false"
                      data-ms-editor="true"
                    /><button
                      @click="incrementQty(item)"
                      type="button"
                      class="font-medium text-indigo-600 hover:text-indigo-500 ml-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </li>

            <!-- More products... -->
          </ul>
        </div>
      </div>
    </div>
    <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
      <div class="flex justify-between text-base font-medium text-gray-900">
        <p>Total</p>
        <p>{{ currency(transaction.grand_total) }}</p>
      </div>
    </div>
    <div v-if="cart.length > 0">
      <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
        <div class="text-base text-gray-900">
          <label for="type" class="block text-sm font-medium text-gray-700"
            >Payment Status</label
          >
          <select
            v-model="transaction.payment_status"
            id="type"
            name="type"
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="awaiting_payment">Awaiting Payment</option>
            <!-- <option value="paid">Paid</option> -->
          </select>
          <span
            class="text-red-500 text-sm block mt-1"
            v-if="errors.payment_status"
          >
            {{ errors.payment_status[0] }}
          </span>
        </div>
      </div>
      <div
        class="pb-6 px-4 sm:px-6"
        v-if="transaction.payment_status == 'paid'"
      >
        <div class="text-base text-gray-900">
          <label for="type" class="block text-sm font-medium text-gray-700"
            >Payment Method</label
          >
          <select
            v-model="transaction.payment_method"
            id="type"
            name="type"
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="transfer">Transfer</option>
            <option value="cash">Cash</option>
          </select>
          <span
            class="text-red-500 text-sm block mt-1"
            v-if="errors.payment_method"
          >
            {{ errors.payment_method[0] }}
          </span>
        </div>
      </div>
      <div class="pb-6 px-4 sm:px-6">
        <div class="text-base font-medium text-gray-900">
          <label
            v-if="canEditNote"
            for="type"
            class="block text-sm font-medium text-gray-700"
            >Note</label
          >
          <input
            v-if="canEditNote"
            v-model="transaction.note"
            autocomplete="off"
            type="text"
            name="address"
            id="address"
            class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          />

          <!-- <p class="mt-0.5 text-sm text-gray-500">
          Shipping and taxes calculated at checkout.
          </p> -->
          <div class="mt-6">
            <button
              @click="saveTransaction"
              class="inline-flex justify-center items-center w-full px-6 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <div v-if="loading" class="btn-spinner mt-1 mr-2" />
              Save Transaction
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cookie from "js-cookie";

export default {
  name: "TransactionCart",
  data() {
    return {
      timer: null,
      canEditNote:
        Cookie.get("adpos_user_role") == "owner" ||
        Cookie.get("adpos_user_role") == "admin_invoice",
    };
  },
  computed: {
    ...mapState("transactions", {
      customer: (state) => state.customer,
      transaction: (state) => state.transaction,
      cart: (state) => state.cart,
      errors: (state) => state.errors,
      loading: (state) => state.loading,
    }),
    currency() {
      var formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return (price) => formatter.format(price);
    },
  },
  methods: {
    playBeepSound() {
      const audio = new Audio("/sound/beep-29.mp3");
      audio.play();
    },
    playDeleteSound() {
      const audio = new Audio("/sound/button-21.mp3");
      audio.play();
    },
    deleteAllCart() {
      this.playDeleteSound();
      this.$store.dispatch("transactions/deleteAllCart");
    },
    decrementQty(item) {
      this.playBeepSound();
      this.$store.dispatch("transactions/updateCart", {
        item: item,
        type: "decrement",
      });
    },
    incrementQty(item) {
      this.playBeepSound();
      this.$store.dispatch("transactions/updateCart", {
        item: item,
        type: "increment",
      });
    },
    updateQuantity(item) {
      this.$store.dispatch("transactions/updateCartUsingInput", {
        item: item,
      });
    },
    saveTransaction() {
      this.$store.dispatch("transactions/saveTransaction", {
        transaction: this.transaction,
        detail_transaction: this.cart,
      });
    },
  },
};
</script>
