<template>
  <navbar />
  <page-title title="Transaction" />
  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col md:flex-row min-w-full">
      <div class="md:w-3/4 flex flex-col md:mr-4">
        <div v-if="customer.customer_id != null">
          <h3 class="text-xl font-medium text-gray-900">
            Customer: {{ customer.name }}
            <button
              @click="resetCustomer"
              type="button"
              class="font-medium text-indigo-600 hover:text-indigo-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </button>
          </h3>
          <p class="mb-4 text-sm text-gray-500">Type: {{ customer.type }}</p>
        </div>
        <transaction-customer v-if="customer.customer_id == null" />
        <transaction-product v-else />
      </div>
      <transaction-cart />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar";
import PageTitle from "@/components/PageTitle";
import TransactionCustomer from "@/views/transactions/Customer";
import TransactionProduct from "@/views/transactions/Product";
import TransactionCart from "@/views/transactions/Cart";

export default {
  name: "Transactions",
  components: {
    Navbar,
    PageTitle,
    TransactionCustomer,
    TransactionProduct,
    TransactionCart,
  },
  computed: {
    ...mapState("transactions", {
      customer: (state) => state.customer,
      transaction: (state) => state.transaction,
    }),
  },
  methods: {
    resetCustomer() {
      this.$store.dispatch("transactions/setCustomer", {
        customer_id: null,
        name: "",
        type: "",
      });
      this.$router.push({ name: "Transactions" });
    },
  },
};
</script>
