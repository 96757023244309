<template>
  <div class="flex items-center w-full max-w-xs mr-4 mb-6">
    <div class="flex w-full bg-white shadow rounded">
      <input
        v-on:keyup="search"
        v-model="keyword"
        autocomplete="off"
        type="text"
        name="search"
        placeholder="Search product..."
        class="relative w-full px-6 py-2 rounded border placeholder-gray-500 text-gray-900 focus:outline-none focus:border-white focus:ring focus:z-10 sm:text-sm"
        spellcheck="false"
        data-ms-editor="true"
      />
    </div>
    <button
      @click="reset"
      type="button"
      class="ml-3 text-sm text-gray-500 hover:text-gray-700 focus:text-indigo-500"
    >
      Reset
    </button>
  </div>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto">
      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Price
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="item in customersProducts.data"
                :key="item.id"
                class="hover:bg-gray-50"
              >
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ item.product.name }}
                      </div>
                      <div class="text-sm text-gray-900">
                        Stock {{ item.product.stock }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900">
                    {{ currency(item.price) }}
                  </div>
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                >
                  <button
                    v-if="item.product.stock > 0"
                    @click="selectProduct(item)"
                    class="text-indigo-600 hover:text-indigo-900"
                  >
                    Add to cart
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-if="customersProducts.data">
    <pagination
      :links="customersProducts.meta.links"
      :active-page="this.$route.name"
      :search-keyword="this.$route.query.search"
    />
  </div>

  <previous-transaction-modal
    v-if="showPreviousTransactionModal"
  ></previous-transaction-modal>
</template>

<script>
import { mapState } from "vuex";
import Pagination from "@/components/Pagination";
import PreviousTransactionModal from "@/views/transactions/PreviousTransactionModal";

export default {
  name: "TransactionProduct",
  components: {
    Pagination,
    PreviousTransactionModal,
  },
  data() {
    return {
      keyword: "",
      timer: null,
    };
  },
  mounted() {
    this.getCustomersProducts();
  },
  computed: {
    ...mapState("customersProducts", {
      customersProducts: (state) => state.customersProducts,
    }),
    ...mapState("transactions", {
      customer: (state) => state.customer,
      showPreviousTransactionModal: (state) =>
        state.showPreviousTransactionModal,
    }),
    currency() {
      var formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return (price) => formatter.format(price);
    },
  },
  methods: {
    getCustomersProducts() {
      this.$store.dispatch("customersProducts/getCustomersProducts", {
        page: this.$route.query.page,
        search: this.$route.query.search,
        by: "customer_id",
        id: this.customer.customer_id,
      });
    },
    search() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: 1,
          search: this.keyword,
          by: "customer_id",
          id: this.customer.customer_id,
        }),
      });

      // Search after user stop typing
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.$store.dispatch("customersProducts/getCustomersProducts", {
          page: 1,
          search: this.keyword,
          by: "customer_id",
          id: this.customer.customer_id,
        });
      }, 2000);
    },
    reset() {
      this.keyword = "";
      this.$store.dispatch("customersProducts/getCustomersProducts", {
        page: 1,
        search: "",
        by: "customer_id",
        id: this.customer.customer_id,
      });
      this.$router.push({ name: "Transactions" });
    },
    selectProduct(product) {
      this.beepSound();
      this.$store.dispatch("transactions/addCart", product);
    },
    beepSound() {
      const audio = new Audio("/sound/beep-29.mp3");
      audio.play();
    },
  },
  watch: {
    $route(to, from) {
      // Watch only the ?page query string
      if (to.query.page !== from.query.page) {
        this.getCustomersProducts();
      }
    },
  },
};
</script>
