<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    :class="{ hidden: this.showPreviousTransactionModal == false }"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-w-2xl w-full sm:align-middle sm:my-8 p-4"
      >
        <p v-if="loadingTransactions" class="mb-6">
          Checking previous transactions...
        </p>

        <div v-if="transactions.data.length > 0 && !loadingTransactions">
          <p class="mb-6">
            Please check the previous transactions today for
            <b>{{ customer.name }}</b>
          </p>
          <table class="mb-8 min-w-full divide-y divide-gray-200 border">
            <thead class="bg-gray-100">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Transaction Code
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Customer Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:block"
                >
                  Payment Status
                </th>
              </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="transaction in transactions.data"
                :key="transaction.id"
                class="hover:bg-gray-50 cursor-pointer"
                @click="openDetailTransaction(transaction.id)"
              >
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ transaction.transaction_code }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ transaction.customer.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap hidden lg:block">
                  {{ transaction.payment_status }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="transactions.data.length == 0 && !loadingTransactions"
          class="text-center mb-8 flex flex-col items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-20 h-20 text-green-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          <p>
            No transactions have been made today yet. <br />
            You're good to go.
          </p>
        </div>

        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            @click="continueTransaction()"
            v-if="!loadingTransactions"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            I've checked and continue
          </button>
          <button
            @click="cancelTransaction()"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PreviousTransactionModal",
  computed: {
    ...mapState("transactions", {
      showPreviousTransactionModal: (state) =>
        state.showPreviousTransactionModal,
      errors: (state) => state.errors,
      loading: (state) => state.loading,
      customer: (state) => state.customer,
    }),
    ...mapState("transactionsHistory", {
      transactions: (state) => state.transactions,
      loadingTransactions: (state) => state.loading,
    }),
  },
  mounted() {
    this.getTransactions();
  },
  methods: {
    getFormattedDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
      const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
      return `${year}-${month}-${day}`;
    },
    getTransactions() {
      this.$store.dispatch("transactionsHistory/getTransactions", {
        page: this.$route.query.page,
        start_date: this.getFormattedDate(new Date()),
        end_date: this.getFormattedDate(new Date()),
        customer_id: this.customer.customer_id,
      });
    },
    openDetailTransaction(id) {
      window.open(`transactions/detail/${id}`, "_blank");
    },
    cancelTransaction() {
      this.$store.dispatch("transactions/setCustomer", {
        customer_id: null,
        name: "",
        type: "",
      });
      this.$router.push({ name: "Transactions" });
    },
    continueTransaction() {
      this.$store.dispatch("transactions/openPreviousTransactionModal", false);
    },
  },
};
</script>
